<template>
  <NuxtLink :to="props.to" :class="{[`link-${color}`]: !!color}">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
const props = defineProps<{
  to: string | object;
  color?: 'white';
}>();
</script>

<style scoped lang="scss">
.link {
  &-white {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
    padding: $p-2 $p-8;
    border-radius: $br-2;
  }
}
</style>
